import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col } from 'react-grid-system'

import Button from '../../components/Button'
import Space from '../../components/Space'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL

const containerClass = classNames({
  'otpimize-workflow-container': true,
})

const descriptionTextClass = classNames({
  'otpimize-workflow-description-text': true,
})

class OptimizeWorkflow extends Component {

  render() {
    const {
      utmContent,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Container>
        <Row>
          <Col sm={12} md={8} offset={{ md: 2 }}>
            <div className={containerClass}>
              <h2>
                {formatMessage({ id: 'optimizeWorkflow.title' })}
              </h2>
              <Space type="xxs" />
              <p className={descriptionTextClass}>
                {formatMessage({ id: 'optimizeWorkflow.description' })}
              </p>
              <Space type="s" />
              <a href={`${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=${utmContent}`}>
                <Button
                  text={formatMessage({ id: 'optimizeWorkflow.action.getStarted' })}
                  color="red"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

OptimizeWorkflow.propTypes = {
  utmContent: PropTypes.string,
}

OptimizeWorkflow.defaultProps = {
  utmContent: undefined,
}

export default injectIntl(OptimizeWorkflow)
