import './style.css'
import React, { Component, Fragment } from 'react'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system'

import Breadcrumb from '../../../components/Breadcrumb'
import Space from '../../../components/Space'

const paragraphClass = classNames({
  'about-header-paragraph': true,
})
const signatureClass = classNames({
  'about-header-paragraph': true,
  'about-header-signature': true,
})

class Header extends Component {

  render() {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Container>
        <Hidden xs sm>
          <Row>
            <Col xs={12}>
              {this.renderBreadcrumb()}
            </Col>
          </Row>
        </Hidden>
        <Row>
          <Col md={6} offset={{ md: 3 }}>
            <h1>
              {formatMessage({ id: 'about.header.title' })}
            </h1>
            <Space type="s" />
            <p className={paragraphClass}>
              {formatMessage({ id: 'about.header.description.1' })}
            </p>
            <Space type="m" />
            <p className={paragraphClass}>
              {formatMessage({ id: 'about.header.description.2' })}
            </p>
            <Space type="m" />
            <p className={signatureClass}>
              {formatMessage({ id: 'about.header.signature' })}
            </p>
          </Col>
        </Row>
      </Container>
    )
  }

  renderBreadcrumb = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props
    const crumbs = [
      { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
      { name: formatMessage({ id: 'breadcrumb.about' }), path: '/about' },
    ]
    return (
      <Fragment>
        <Space type="m" />
        <Breadcrumb content={crumbs} />
        <Space type="thin" />
      </Fragment>
    )
  }
}

export default injectIntl(Header)
