import './style.css'
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18nLink from '../I18nLink'
import Image from '../Image'

import chevronRight from '../../img/icons/icon_chevron_right.svg'

const breadcrumbContainerClass = classNames({
  'breadcrumb-container': true,
})
const separatorImageClass = classNames({
  'breadcrumb-separator': true,
})

class Breadcrumb extends Component {

  render() {
    const { content } = this.props

    return (
      <Fragment>
        <div className={breadcrumbContainerClass}>
          {content.map((crumb, index) => this.renderCrumb(crumb, index === content.length - 1))}
        </div>
      </Fragment>
    )
  }

  renderSeparator = () => {
    return (
      <span className={separatorImageClass}>
        <Image
          alt="chevron"
          src={chevronRight}
        />
      </span>
    )
  }

  renderCrumb = (crumb, isLast) => {
    const {
      name,
      path,
    } = crumb

    return (
      <Fragment key={`crumb_${name}`}>
        <I18nLink to={path} alt={name}>
          {name}
        </I18nLink>
        {!isLast && this.renderSeparator()}
      </Fragment>
    )
  }
}

Breadcrumb.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })),
}

export default Breadcrumb
