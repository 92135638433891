import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Hidden, ScreenClassRender, Visible } from 'react-grid-system'
import { StaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import Button from '../../components/Button'
import I18nLink from '../../components/I18nLink'
import Inline from '../../components/Inline'
import Space from '../../components/Space'

import smallArrowRightBluePicture from '../../img/icons/icon_small_arrow_right_blue.svg'

const backgroundClass = classNames({
  'security-mini-background': true,
})

const containerClass = classNames({
  'security-mini-container': true,
})

const contentContainerClass = classNames({
  'security-mini-content-container': true,
})

class SecurityMini extends Component {

  render() {
    return (
      <div className={backgroundClass}>
        <Container>
          <Space type="l" />
          <Row>
            {this.renderLeftPane()}
            {this.renderRightPane()}
          </Row>
          <Space type="xl" />
        </Container>
      </div>
    )
  }

  renderLeftPane = () => {
    const {
      intl: {
        locale,
      },
    } = this.props

    return (
      <Col sm={12} md={6}>
        <StaticQuery
          query={query}
          render={data => (
            <div className={containerClass}>
              <ScreenClassRender render={screenClass => (
                <GatsbyImage
                  fluid={data.lockPicture.childImageSharp.fluid}
                  alt="lock"
                  width={['xs', 'sm'].includes(screenClass) ? '128px' : '176px'}
                  style={{
                    width: ['xs', 'sm'].includes(screenClass) ? '128px' : '176px',
                    maxWidth: '100%',
                  }}
                />
              )} />

              <Visible xs sm>
                <Space type="xxs" />
              </Visible>
              <Hidden xs sm>
                <Inline type="l" />
              </Hidden>

              <GatsbyImage
                fluid={locale === 'fr' ?
                  data.policiesFrench.childImageSharp.fluid :
                  data.policiesEnglish.childImageSharp.fluid
                }
                alt="policies"
                width={244}
                style={{
                  width: 244,
                  maxWidth: '100%',
                }}
              />
            </div>
          )}
        />
      </Col>
    )
  }

  renderRightPane = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Hidden xs sm>
        <Col md={4} offset={{ md: 1 }}>
          <div className={contentContainerClass}>
            <h2>
              {formatMessage({ id: 'security.mini.title' })}
            </h2>
            <Space type="xs" />
            <I18nLink to={formatMessage({ id: 'security.mini.action.uri' })}>
              <Button
                type="button"
                color="outline"
                text={formatMessage({ id: 'security.mini.action.readMore' })}
                iconRight={smallArrowRightBluePicture}
              />
            </I18nLink>
          </div>
        </Col>
      </Hidden>
    )
  }
}

const query = graphql`
  query {
          lockPicture: file(relativePath: {eq: "artworks/artwork_security_lock.png" }) {
          ...fluidImage
        }
        policiesFrench: file(relativePath: {eq: "artworks/artwork_footer_security_fr.png" }) {
          ...fluidImage
        }
        policiesEnglish: file(relativePath: {eq: "artworks/artwork_footer_security_en.png" }) {
          ...fluidImage
        }
        }
      `

export default injectIntl(SecurityMini)
