import React from 'react'
import { injectIntl } from 'react-intl'
import { Visible, Hidden } from 'react-grid-system'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/About/Header'
import Values from '../scenes/About/Values'
import Team from '../scenes/About/Team'
import Location from '../scenes/About/Location'
import OptimizeWorkflow from '../scenes/OptimizeWorkflow'
import SecurityMini from '../scenes/SecurityMini'
import Footer from '../scenes/Footer'

const About = ({ intl: { formatMessage } }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.about.title' })}
      metaDescription={formatMessage({ id: 'meta.about.description' })}
    />
    <Navigation />
    <Header />
    <Space type="giant" />
    <Values />
    <Space type="giant" />

    <Visible xs sm>
      <Location />
      <Space type="giant" />
    </Visible>

    <Team />
    <Space type="giant" />

    <Hidden xs sm>
      <Location />
      <Space type="giant" />
    </Hidden>

    <OptimizeWorkflow
      utmContent="about-optimize"
    />
    <Space type="giant" />
    <SecurityMini />
    <Footer />
  </Layout>
)

export default withContext(injectContext(withIntl(injectIntl(About))))
