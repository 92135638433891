import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from '../Image'
import Space from '../Space'

const COLORS = ['purple', 'orange']

const containerClass = classNames({
  'promise-container': true,
})

const pictureContainerClass = classNames({
  'promise-picture-container': true,
})

const titleTextClass = classNames({
  'promise-title-text': true,
})

const descriptionTextClass = classNames({
  'promise-description-text': true,
})

class Promise extends Component {

  render() {
    const {
      picture,
      alt,
      title,
      description,
      color,
    } = this.props

    const pictureBackgroundClass = classNames({
      'promise-picture-background': true,
      [`promise-picture-background-${color}`]: true,
    })

    return (
      <div className={containerClass}>
        <div className={pictureContainerClass}>
          <div className={pictureBackgroundClass} />
          <Image
            src={picture}
            alt={alt}
          />
        </div>
        <Space type="s" />
        <h3 className={titleTextClass}>
          {title}
        </h3>
        <Space type="m" />
        <p className={descriptionTextClass}>
          {description}
        </p>
      </div>
    )
  }
}

Promise.propTypes = {
  picture: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.oneOf(COLORS).isRequired,
}

Promise.defaultProps = {
  picture: undefined,
  alt: undefined,
  title: undefined,
  description: undefined,
  color: COLORS[0],
}

export default Promise
