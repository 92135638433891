import './style.css'
import React, { Component } from 'react'
import { Container, Row, Col, Visible } from 'react-grid-system'
import { injectIntl, FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import Space from '../../../components/Space'

const titleContainerClass = classNames({
  'about-location-title-container': true,
})
const addressTitleClass = classNames({
  'about-location-address-title': true,
})
const addressBodyClass = classNames({
  'about-location-address-body': true,
})
const contactClass = classNames({
  'about-location-contact': true,
})
const clickableClass = classNames({
  'about-location-contact-clickable': true,
})

class Location extends Component {

  // Handlers
  handleIntercomClick = () => {
    if (typeof window !== 'undefined') {
      window.Intercom('show')
    }
  }


  // Render
  render() {
    return (
      <Container>
        <Row>
          {this.renderHeader()}
        </Row>
        <Space type="xl" />
        <Row>
          {this.renderAdress('lyon')}
          {this.renderAdress('paris')}
        </Row>
        <Space type="xxl" />
        <Row>
          {this.renderContact()}
        </Row>
      </Container>
    )
  }

  renderHeader = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12}>
        <div className={titleContainerClass}>
          <h2>
            {formatMessage({ id: 'about.location.title' })}
          </h2>
        </div>
      </Col>
    )
  }

  renderAdress = (city) => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col md={3} offset={{ md: 2 }}>
        <p className={addressTitleClass}>{formatMessage({ id: `about.location.${city}.title` })}</p>
        <p className={addressBodyClass}>{formatMessage({ id: `about.location.${city}.address` })}</p>
        <Visible xs sm>
          <Space type="xs" />
        </Visible>
      </Col>
    )
  }

  renderContact = () => {
    return (
      <Col md={4} offset={{ md: 4 }}>
        <p className={contactClass}>
          <FormattedMessage
            id="about.location.contact"
            values={{
              email: <a className={clickableClass} href={'mailto:contact@wuha.io'}>contact@wuha.io</a>,
              chat: <span className={clickableClass} onClick={this.handleIntercomClick}>Chat</span>,
            }}
          />
        </p>
      </Col>
    )
  }
}

export default injectIntl(Location)
