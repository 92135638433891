import './style.css'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Image from '../../../../../components/Image'
import Space from '../../../../../components/Space'

import linkedInImage from '../../../../../img/icons/icon_social_linkedin_gray.svg'

const LINKEDIN_ADDR = 'https://www.linkedin.com/in'

const containerClass = classNames({
  'about-team-member-container': true,
})
const nameAndTitleClass = classNames({
  'about-team-member-nametitle': true,
})
const linkedinContainerClass = classNames({
  'about-team-member-linkedin': true,
})

class Member extends Component {

  render() {
    const {
      picture,
      name,
      title,
      description,
    } = this.props

    return (
      <div className={containerClass}>
        {this.renderLinkedin()}
        <Image
          src={picture}
          alt={name}
          height={56}
        />
        <Space type="xs" />
        <span className={nameAndTitleClass}>{name},<br />{title}</span>
        <Space type="s" />
        <p>{description}</p>
      </div>
    )
  }

  renderLinkedin = () => {
    const {
      linkedin,
    } = this.props

    if (!linkedin) {
      return null
    }

    return (
      <a
        className={linkedinContainerClass}
        href={`${LINKEDIN_ADDR}/${linkedin}`}
      >
        <Image
          src={linkedInImage}
          alt={'linkedin'}
          height={32}
        />
      </a>
    )
  }
}

Member.propTypes = {
  picture: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkedin: PropTypes.string,
}

export default injectIntl(Member)
