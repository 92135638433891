import './style.css'
import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

import Space from '../../../components/Space'
import Member from './components/Member'

import sachaPicture from '../../../img/team/sacha.png'
import antoninPicture from '../../../img/team/antonin.png'
import stevenPicture from '../../../img/team/steven.png'
import juliettePicture from '../../../img/team/juliette.png'
import charlyPicture from '../../../img/team/charly.png'
import timotheePicture from '../../../img/team/timothee.png'
import jeromePicture from '../../../img/team/jerome.png'
import yohannPicture from '../../../img/team/yohann.png'
import alexisPicture from '../../../img/team/alexis.png'
import aymericPicture from '../../../img/team/aymeric.png'
import maximePicture from '../../../img/team/maxime.png'
import raphaelPicture from '../../../img/team/raphael.png'
import kassimPicture from '../../../img/team/kassim.png'

const titleContainerClass = classNames({
  'about-values-title-container': true,
})

class Team extends Component {
  render() {
    return (
      <Container>
        <Row>
          {this.renderHeader()}
        </Row>
        <Space type="xl" />
        {this.renderTeam()}
      </Container>
    )
  }

  renderHeader = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12}>
        <div className={titleContainerClass}>
          <h2>
            {formatMessage({ id: 'about.team.title' })}
          </h2>
        </div>
      </Col>
    )
  }

  renderMember = (member) => {
    const {
      picture,
      name,
      title,
      description,
      linkedin,
    } = member

    return (
      <Fragment>
        <Member
          picture={picture}
          name={name}
          title={title}
          description={description}
          linkedin={linkedin}
        />
        <Space type="s" />
      </Fragment>
    )
  }

  renderTeam = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    const members = [
      {
        picture: sachaPicture,
        name: 'Sacha Gallo Parouty',
        title: formatMessage({ id: 'about.team.members.sacha.title' }),
        description: formatMessage({ id: 'about.team.members.sacha.description' }),
        linkedin: 'sacha-gallo-parouty-86922244',
      },
      {
        picture: antoninPicture,
        name: 'Antonin Mathez',
        title: formatMessage({ id: 'about.team.members.antonin.title' }),
        description: formatMessage({ id: 'about.team.members.antonin.description' }),
        linkedin: 'antonin-mathez-26628147',
      },
      {
        picture: stevenPicture,
        name: 'Steven Hall',
        title: formatMessage({ id: 'about.team.members.steven.title' }),
        description: formatMessage({ id: 'about.team.members.steven.description' }),
        linkedin: 'steven-hall-339bb9113',
      },
      {
        picture: juliettePicture,
        name: 'Juliette Delaunay',
        title: formatMessage({ id: 'about.team.members.juliette.title' }),
        description: formatMessage({ id: 'about.team.members.juliette.description' }),
        linkedin: 'juliette-delaunay-052986a2',
      },
      {
        picture: charlyPicture,
        name: 'Charly Maillefaud',
        title: formatMessage({ id: 'about.team.members.charly.title' }),
        description: formatMessage({ id: 'about.team.members.charly.description' }),
        linkedin: 'charlymaillefaud',
      },
      {
        picture: alexisPicture,
        name: 'Alexis Quesnel',
        title: formatMessage({ id: 'about.team.members.alexis.title' }),
        description: formatMessage({ id: 'about.team.members.alexis.description' }),
        linkedin: '/alexis-quesnel-8b036a14b',
      },
      {
        picture: timotheePicture,
        name: 'Timothée Dehouck',
        title: formatMessage({ id: 'about.team.members.timothee.title' }),
        description: formatMessage({ id: 'about.team.members.timothee.description' }),
        linkedin: 'timothée-dehouck-82076599',
      },
      {
        picture: yohannPicture,
        name: 'Yohann Hako Moukam',
        title: formatMessage({ id: 'about.team.members.yohann.title' }),
        description: formatMessage({ id: 'about.team.members.yohann.description' }),
        linkedin: null,
      },
      {
        picture: raphaelPicture,
        name: 'Raphaël Bournhonesque',
        title: formatMessage({ id: 'about.team.members.raphael.title' }),
        description: formatMessage({ id: 'about.team.members.raphael.description' }),
        linkedin: 'rbournho',
      },
      {
        picture: kassimPicture,
        name: 'Kassim Hassani',
        title: formatMessage({ id: 'about.team.members.kassim.title' }),
        description: formatMessage({ id: 'about.team.members.kassim.description' }),
        linkedin: 'kassim-hassani',
      },
      {
        picture: aymericPicture,
        name: 'Aymeric Chauvin',
        title: formatMessage({ id: 'about.team.members.aymeric.title' }),
        description: formatMessage({ id: 'about.team.members.aymeric.description' }),
        linkedin: 'aymeric-chauvin-76963454',
      },
      {
        picture: maximePicture,
        name: 'Maxime Vast',
        title: formatMessage({ id: 'about.team.members.maxime.title' }),
        description: formatMessage({ id: 'about.team.members.maxime.description' }),
        linkedin: 'maxime-vast',
      },
      {
        picture: jeromePicture,
        name: 'Jérôme Pin',
        title: formatMessage({ id: 'about.team.members.jerome.title' }),
        description: formatMessage({ id: 'about.team.members.jerome.description' }),
        linkedin: 'jeromepin',
      },
    ]

    return (
      <Fragment>
        <Row>
          <Col md={3} offset={{ md: 1.5 }}>
            {this.renderMember(members[0])}
          </Col>
          <Col md={3} >
            {this.renderMember(members[1])}
          </Col>
          <Col md={3} >
            {this.renderMember(members[2])}
          </Col>
        </Row>
        <Row>
          <Col md={3} offset={{ md: 1.5 }}>
            {this.renderMember(members[3])}
          </Col>
          <Col md={3} >
            {this.renderMember(members[4])}
          </Col>
          <Col md={3} >
            {this.renderMember(members[5])}
          </Col>
        </Row>
        <Row>
          <Col md={3} offset={{ md: 1.5 }}>
            {this.renderMember(members[6])}
          </Col>
          <Col md={3} >
            {this.renderMember(members[7])}
          </Col>
          <Col md={3} >
            {this.renderMember(members[8])}
          </Col>
        </Row>
        <Row>
          <Col md={3} offset={{ md: 1.5 }}>
            {this.renderMember(members[9])}
          </Col>
          <Col md={3} >
            {this.renderMember(members[10])}
          </Col>
          <Col md={3} >
            {this.renderMember(members[11])}
          </Col>
        </Row>
        <Row>
          <Col md={3} offset={{ md: 1.5 }}>
            {this.renderMember(members[12])}
          </Col>
        </Row>
      </Fragment>
    )
  }
}

export default injectIntl(Team)
