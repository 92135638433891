import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible } from 'react-grid-system'

import Space from '../../../components/Space'
import Promise from '../../../components/Promise'

import transparencyPicture from '../../../img/icons/icon_circle.svg'
import teamspiritPicture from '../../../img/icons/icon_teamspirit.svg'
import expectationsPicture from '../../../img/icons/icon_sharp.svg'

const containerClass = classNames({
  'about-values-container': true,
})

const titleContainerClass = classNames({
  'about-values-title-container': true,
})

class Values extends Component {

  render() {
    return (
      <div className={containerClass}>
        <Container>
          <Row>
            {this.renderHeader()}
          </Row>
          <Space type="xl" />
          <Row>
            {this.renderPromises()}
          </Row>
        </Container>
      </div>
    )
  }

  renderHeader = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12}>
        <div className={titleContainerClass}>
          <h2>
            {formatMessage({ id: 'about.values.title' })}
          </h2>
        </div>
      </Col>
    )
  }

  renderPromises = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Fragment>
        <Col sm={12} md={4}>
          <Promise
            picture={transparencyPicture}
            title={formatMessage({ id: 'about.values.transparency.title' })}
            description={formatMessage({ id: 'about.values.transparency.description' })}
            color={'orange'}
            alt={'transparency'}
          />
          <Visible xs sm>
            <Space type="m" />
          </Visible>
        </Col>

        <Col sm={12} md={4}>
          <Promise
            picture={teamspiritPicture}
            title={formatMessage({ id: 'about.values.teamspirit.title' })}
            description={formatMessage({ id: 'about.values.teamspirit.description' })}
            color={'orange'}
            alt={'teamspirit'}
          />
          <Visible xs sm md>
            <Space type="m" />
          </Visible>
        </Col>

        <Col sm={12} md={4}>
          <Promise
            picture={expectationsPicture}
            title={formatMessage({ id: 'about.values.expectations.title' })}
            description={formatMessage({ id: 'about.values.expectations.description' })}
            color={'orange'}
            alt={'expectations'}
          />
        </Col>

      </Fragment>
    )
  }
}

export default injectIntl(Values)
